/**
 * Markdown Page parser component receives data from pageQuery
 */
import React from "react";

const MarkDownParser = data => {
  // console.log("MarkDownPage component called: data: ", data);
  const { frontmatter, html } = data.data.allMarkdownRemark.edges[0].node;
  // console.log(frontmatter.title);

  return (
    <div>
      <h1>{frontmatter.title}</h1>
      <p>{frontmatter.date}</p>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  );
};

export default MarkDownParser;
